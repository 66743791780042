import { LoginFormData } from '@components/Auth/SignInForm/validations';
import { RegisterFormData, CheckboxesData } from '@components/Auth/SignUpForm/validations';
import { IFormData } from '@components/WatchForm/useWatchFormLogic';
import { getBooleanValue, getMonthNumericalRepresentation } from 'constants/watch';
import { axios } from '../lib/axios';
import jwt_decode from 'jwt-decode';

export const getSerialNumberByBrand = async (brand: string) => {
  const accessToken = localStorage.getItem('accessToken');
  try {
    const formattedBrand = brand.replaceAll('&', '%26');
    const response = await axios.get(`chart_history/refnos/?brand=${formattedBrand}`, {
      headers: {
        Authorization: `Token ${accessToken}`
      }
    });
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const sendDataToApi = async (formData: IFormData) => {
  const accessToken = localStorage.getItem('accessToken');

  const readyFormData = {
    brand: formData?.selectedBrand,
    refno: formData?.selectedSerialNumber,
    condition: formData?.selectedCondition ? formData?.selectedCondition : 'no condition',
    papers: getBooleanValue(formData?.selectedPapers as string),
    box: getBooleanValue(formData?.selectedBox as string),
    karats: Number(formData?.selectedKarats),
    band_material: formData?.selectedBandMaterial ? formData?.selectedBandMaterial.toString().toLowerCase() : 'unknown',
    case_material: formData?.selectedCaseMaterial ? formData?.selectedCaseMaterial.toString().toLowerCase() : 'unknown',
    year: '2023',
    month: '09',
    marketplace: 'Ebay',
    dial_color: formData.selectedDialColor.toString().toLowerCase() ? formData.selectedDialColor.toString().toLowerCase() : 'unknown' ,
    gold_type:  formData.selectedGoldType.toString().toLowerCase() ? formData.selectedGoldType.toString().toLowerCase() : 'not present',
    country_code: 'US' // TODO replace with real value from form
  };


  try {
    const response = await axios.post('watch_inference/', readyFormData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${accessToken}`
      }
    });

    return response;
  } catch (error) {
    console.error('Error sending data:', error);
  }
};

export const checkboxesApiCall = async (formData: CheckboxesData) => {
  const accessToken = localStorage.getItem('accessToken');

  const readyFormData = {
    email: formData.email,
    lending: formData.lending,
    selling: formData.selling,
    other_insurance: formData.insurance
  };

  try {
    const response = await axios.post('customer_interest/', readyFormData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${accessToken}`
      }
    });

    return response;
  } catch (error) {
    console.error('Error sending data:', error);
  }
};

export const Register = async (body: RegisterFormData) => {
  const readyBodyData = {
    password: body.password,
    confirm_password: body.confirmPassword,
    email: body.email
  };

  try {
    const response = await axios.post('user/registration/', readyBodyData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response) {
      localStorage.setItem('accessToken', response.data.tokens.access);
      localStorage.setItem('refreshToken', response.data.tokens.refresh);
    }
    return response;
  } catch (error: any) {
    console.error('Error sending data:', error);
    return error.response;
  }
};

export const LogIn = async (body: LoginFormData) => {
  const readyBodyData = {
    password: body.password,
    email: body.email
  };

  try {
    const response = await axios.post('user/login/', readyBodyData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response) {
      localStorage.setItem('accessToken', response.data.tokens.access);
      localStorage.setItem('refreshToken', response.data.tokens.refresh);
    }
    return response;
  } catch (error: any) {
    console.error('Error sending data:', error);
    return error.response;
  }
};

export const LogOut = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const accessToken = localStorage.getItem('accessToken');

  const readyBodyData = {
    refresh: refreshToken
  };

  try {
    const response = await axios.post('user/logout/', readyBodyData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${accessToken}`
      }
    });

    if (response) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

export const Refresh = async (navigate: any) => {
  const refreshToken = localStorage.getItem('refreshToken');
  const accessToken = localStorage.getItem('accessToken');

  const decodedToken: any = jwt_decode(accessToken as string);

  const currentTime = Math.floor(Date.now() / 1000);

  const isTokenExpired = currentTime > decodedToken.exp;

  const readyBodyData = {
    refresh: refreshToken
  };

  if (accessToken && !isTokenExpired) {
    try {
      const response = await axios.post('user/token/refresh/', readyBodyData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`
        }
      });

      if (response) {
        localStorage.setItem('accessToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);
      } else {
        navigate('/auth/sign-in');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
};
