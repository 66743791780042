import React, { useEffect } from 'react';

interface IPopupProps {
  isPopupOpen: boolean;
  onClose: () => void;
}

const Popup: React.FC<IPopupProps> = ({ isPopupOpen, onClose }) => {
  return (
    <div>
      {isPopupOpen && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 fadeIn" onClick={onClose}>
          <div className="bg-white p-6 rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
            <h2 className="text-xl font-semibold mb-4">Error</h2>
            <p>Sorry, nothing was found matching your search criteria</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;
