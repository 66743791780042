import React, { useContext, useEffect } from 'react';
import { ResponsiveContainer } from 'recharts';
import { WatchForm } from '@components/WatchForm';
import { Refresh } from 'api/api';
import SinglePrice from "@components/SinglePrice";
import AuthContext from 'context/AuthContext';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

export const HomePage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const startTokenRefresh = () => {
      const interval = setInterval(() => {
        Refresh(navigate);
      }, 900000); // Refresh token every 15 minutes (900000 milliseconds)

      return () => clearInterval(interval);
    };
    startTokenRefresh();
  }, []);

  const { priceRange } = useContext(AuthContext);

  const singlePrice = Number(priceRange[0]);

  return (
    <div className={clsx('grid', { 'grid-cols-3': !!singlePrice, 'grid-cols-1': !singlePrice })}>
      {/* Desktop Layout */}
      <div className="hidden col-span-2 sm:block">
        <WatchForm />
      </div>
      {singlePrice ? (
        <div className="bg-gray-bg mr-12 h-52 w-64 py-12 mt-32 hidden sm:block rounded-xl">	
          <ResponsiveContainer width="100%" height="100%">
            <div className="mr-2">{SinglePrice({ price: singlePrice })}</div>
          </ResponsiveContainer>
        </div>
      ) : null}
      {/* Mobile Layout */}
      <div className="col-span-3 sm:hidden">
        <WatchForm />
        {singlePrice ? (
          <div className="bg-gray-bg h-40 w-full mx-auto py-5 mt-12 rounded-xl">
            <ResponsiveContainer width="95%" height="100%">
              {SinglePrice({ price: singlePrice})}
            </ResponsiveContainer>
          </div>
        ) : null}
      </div>
    </div>
  );
};
