import React from 'react';
import { AuthLayout, MainLayout } from '@components/Layout';
import { PrivateRoutes } from './private/PrivateRoutes';
import { authRoutes, commonRoutes } from './public';
import { privateRoutes } from './private';

export const routes = [
  {
    path: '/auth',
    element: <AuthLayout />,
    children: authRoutes
  },
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: '/',
        element: <MainLayout />,
        children: privateRoutes
      }
    ]
  },
  ...commonRoutes
];
