import React from 'react';
import { SignInPage, SignUpPage } from '../../pages';
import { RouteObject } from 'react-router-dom';

export const authRoutes: RouteObject[] = [
  {
    path: 'sign-up',
    element: <SignUpPage />,
    index: true
  },
  {
    path: 'sign-in',
    element: <SignInPage />
  },
  {
    path: 'reset-password/:email/:token',
    element: <div>reset password</div>
  }
];

export const commonRoutes: RouteObject[] = [
  {
    path: '/terms-and-privacy-policy',
    element: <div>privacy policy</div>
  },
  {
    path: '/forgot-password',
    element: <div>forgot password</div>
  },
  {
    path: '*',
    element: <h1>Not Found</h1>
  }
];
