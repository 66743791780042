import { HomePage } from '../../pages/HomePage';
import { RouteObject } from 'react-router-dom';
import { RedirectToHome } from 'pages/RedirectToHome';

export const privateRoutes: RouteObject[] = [
  {
    path: '',
    element: <RedirectToHome />
  },
  {
    path: 'home',
    element: <HomePage />
  }
];
