import React from 'react';
import { useNavigate } from 'react-router-dom';

export const RedirectToHome = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
      navigate('/home', { replace: true });
    }, [navigate]);
  
    return null;
  };