import { Container } from '@components/Container';
import { InputField, Button } from '@components/Form';
import { Link } from 'react-router-dom';
import { useSignupLogic } from './useSignupLogic';
import { Checkbox } from '@components/Form';
import clsx from 'clsx';

export const SignUpForm = () => {
  const {
    email,
    password,
    confirmPassword,
    onEmailChange,
    onPasswordChange,
    onConfirmPasswordChange,
    onSubmit,
    error,
    isDisplayError,
    loading,
    selling,
    insurance,
    lending,
    setSelling,
    setInsurance,
    setLending,
    isInvalid
  } = useSignupLogic();


  const windowHeightSmall = window.innerHeight < 750;

  const desktop = window.innerHeight > 1000;

  return (
    <Container>
      <h1 className={clsx("text-2xl font-bold mb-8 text-center", {'mt-48' : windowHeightSmall, 'mt-12': !windowHeightSmall && desktop })} >Sign Up</h1>
      <form className="w-[185px] sm:w-[400px] mx-auto w-full" onSubmit={onSubmit}>
        <InputField
          label="Email"
          type="email"
          error={error.email}
          value={email}
          onChange={onEmailChange}
          containerClassName="mb-8"
        />
        <InputField
          type="password"
          label="Password"
          error={error.password}
          value={password}
          onChange={onPasswordChange}
          containerClassName="mb-8"
        />
        <InputField
          type="password"
          label="Confirm password"
          error={error.confirmPassword}
          value={confirmPassword}
          onChange={onConfirmPasswordChange}
        />
        <div className="mb-4 mt-8 w-full">
          <p className="text-gray-text">Tell us what you're interesed in:</p>
          <div className="ml-6 mt-2">
            <Checkbox
              checked={lending}
              handleChange={() => setLending(!lending)}
              label="Using Barker valuations for Lending"
            />
            <Checkbox
              checked={selling}
              handleChange={() => setSelling(!selling)}
              label="Using Barker valuations for Seling"
            />
            <Checkbox
              checked={insurance}
              handleChange={() => setInsurance(!insurance)}
              label="Using Barker for other valuation needs - Insurance etc"
            />
          </div>
        </div>
        <p className="mt-4">
          <Link to="/auth/sign-in" className="text-sm text-blue-600 hover:text-blue-800 cursor-pointer">
            Already have an account?
          </Link>
        </p>
        {isDisplayError && <p className="mt-4 text-lg text-red-500">{isDisplayError}</p>}
        {loading ? (
          <Button className="mt-8">
            <div className="w-full h-full flex justify-center items-center">
              <div className="buttonSpinner" />
            </div>
          </Button>
        ) : (
          <Button className="mt-8" type="submit" disabled={isInvalid}>
            Sign Up
          </Button>
        )}
      </form>
    </Container>
  );
};
