import React from 'react';

import clsx from "clsx";

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
}

export const Container = ({ children, className }: ContainerProps) => {
  return (
    <div className={
      clsx('border bg-white border-1 border-b-gray-border p-16 w-fit',
            className
      )}>
      {children}
    </div>
  )
}
