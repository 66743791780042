import { LogIn } from 'api/api';
import AuthContext from 'context/AuthContext';
import { useState, useCallback, ChangeEvent, FormEvent, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateSignInForm, SignInFormErrors } from './validations';

const INITIAL_FORM_ERRORS: SignInFormErrors = {
  email: '',
  password: ''
};

export const useSignInLogic = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isDisplayError, setIsDisplayError] = useState<string>('');
  const [error, setError] = useState<SignInFormErrors>(INITIAL_FORM_ERRORS);
  const [loading, setLoading] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const { setAuthenticated, setGlobalEmail } = useContext(AuthContext);

  useEffect(() => {
    if(email.length < 1){
      setIsInvalid(true)
    }
    if(password.length < 7){
      setIsInvalid(true)
    }
    if(email.length > 0 && password.length > 7){
      setIsInvalid(false)
    }
  }, [password, email])

  const onPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, password: '' }));
  }, []);

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, email: '' }));
  }, []);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      const submitData = { email, password };
      const errors = validateSignInForm(submitData);
      if (!errors.email && !errors.password) {
        setLoading(true);
        LogIn({ password: password, email: email })
          .then((res) => {
            if(res.status === 200){
              setAuthenticated(true)
              setGlobalEmail(email)
              navigate('/home');
            }else {
              throw(res)
            }
          })
          .catch((e: any) => {
            console.error(e);
            setIsDisplayError(e.data?.non_field_errors[0]);
          })
          .finally(() => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
          });
      } else {
        setError(errors);
      }
    },
    [email, password, navigate, setAuthenticated, setGlobalEmail]
  );

  return { email, password, onEmailChange, onPasswordChange, onSubmit, error, isDisplayError, loading , isInvalid};
};
