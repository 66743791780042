import React, { useState, useEffect } from 'react';

interface IAuthContext {
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  email: string;
  setGlobalEmail: (email: string) => void;
  priceRange: ['', ''];
  setPriceRange: (priceRange: ['', '']) => void;
}

type ContextProps = {
  children: React.ReactNode;
};

const AuthContext = React.createContext<IAuthContext>({
  authenticated: false,
  setAuthenticated: () => {},
  email: '',
  setGlobalEmail: () => {},
  priceRange: ['', ''],
  setPriceRange: () => {}
});

export const AuthProvider: React.FC<ContextProps> = ({ children }: ContextProps) => {
  const [authenticated, setAuthenticated] = useState(() => {
    return JSON.parse(localStorage.getItem('authenticated') as string) || false;
  });

  const [email, setGlobalEmail] = useState(() => {
    return JSON.parse(localStorage.getItem('email') as string) || '';
  });

  const [priceRange, setPriceRange] = useState<['', '']>(['', '']);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('authenticated', JSON.stringify(authenticated));
  }, [authenticated]);

  useEffect(() => {
    localStorage.setItem('email', JSON.stringify(email));
  }, [email]);

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated, email, setGlobalEmail, priceRange, setPriceRange }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
