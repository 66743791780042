export type SignInFormErrors = {
  email: string;
  password: string;
};

export type LoginFormData = {
  email: string;
  password: string;
};

// TODO: Extend the validations (potentially make them generic, so it also works for the sign in)
export const validateSignInForm = (data: LoginFormData): SignInFormErrors => {
  const errors: SignInFormErrors = { email: '', password: '' };
  const { email, password } = data;

  if (!email) {
    errors.email = 'This field is required';
  }

  if (!password) {
    errors.password = 'This field is required';
  }
  return errors;
};
