import React from 'react';
import { getPriceRepresntValue } from '../PriceRange/helpers';

interface ISinglePriceProps {
  price: number;
}

const SinglePrice = ({ price }: ISinglePriceProps) => {

  return (
    <div className="relative w-10/11 mt-6 ml-2">
      <h3 className="text-xl font-semibold mb-2 text-center">Price</h3>
      <p className="text-xl text-center">{getPriceRepresntValue(price.toFixed(0))}</p>
    </div>
  );
};

export default SinglePrice;
