import React from 'react';
import { Header } from '@components/Header';
import { Outlet } from 'react-router-dom';

export const MainLayout = () => (
  <div className="w-screen">
    <Header />
    <div className="flex items-center justify-center h-full py-16">
      <Outlet />
    </div>
  </div>
);
