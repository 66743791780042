import AuthContext from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { checkboxesApiCall, sendDataToApi } from '../../api/api';

export interface IFormData {
  selectedBrand: string | number;
  selectedSerialNumber: string | number;
  selectedCondition: string | number;
  selectedPapers: string | number;
  selectedBox: string | number;
  selectedKarats: string | number;
  selectedBandMaterial: string | number;
  selectedCaseMaterial: string | number;
  selectedYear: string | number;
  selectedMonth: string | number;
  selectedDialColor: string | number;
  selectedGoldType: string | number;
  marketplace: string;
}

export const useWatchFormLogic = () => {
  const [formData, setFormData] = useState<IFormData>({
    selectedBrand: '',
    selectedSerialNumber: '',
    selectedCondition: '',
    selectedPapers: '',
    selectedBox: '',
    selectedKarats: '',
    selectedBandMaterial: '',
    selectedCaseMaterial: '',
    selectedYear: '',
    selectedMonth: '',
    selectedDialColor: '',
    selectedGoldType: '',
    marketplace: '',
  });
  const [sendDataLoading, setSendDataLoading] = useState<boolean>(false);

  const [serialNumbers, setSerialNumbers] = useState<[]>([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);

  const { setPriceRange } = useContext(AuthContext);

  useEffect(() => {
    const excludedFields = [
      'selectedBandMaterial',
      'selectedCaseMaterial',
      'selectedCondition',
      'selectedYear',
      'selectedMonth',
      'selectedKarats',
      'selectedDialColor',
      'selectedGoldType',
      'marketplace'
    ];

    const hasEmptyField = Object.keys(formData).some(
      (key) => formData[key as keyof IFormData] === '' && !excludedFields.includes(key)
    );
    setIsButtonDisabled(hasEmptyField);
  }, [formData]);

  const handleBrandChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedBrand: value }));
  };

  const handleSerialNumberChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedSerialNumber: value }));
  };

  const handleConditionChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedCondition: value }));
  };

  const handlePapersChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedPapers: value }));
  };

  const handleBoxChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedBox: value }));
  };

  const handleKaratsChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedKarats: value }));
  };

  const handleBandMaterialChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedBandMaterial: value }));
  };

  const handleCaseMaterialChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedCaseMaterial: value }));
  };

  const handleYearChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedYear: value }));
  };

  const handleMonthChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedMonth: value }));
  };

  const handleDialColorChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedDialColor: value }));
  };

  const handleGoldTypeChange = (value: string | number) => {
    setFormData((prevData) => ({ ...prevData, selectedGoldType: value }));
  };

  const handleMarketplaceChange = (value: string) => {
    setFormData((prevData) => ({ ...prevData, marketplace: value }));
  };


  const clearPriceRangeAndOpenPopup = () => {
    setPriceRange(['', '']);
    setPopupOpen(true);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setSendDataLoading(true);
  
    try {
      const res1 = await sendDataToApi(formData);
  
      if (res1?.data?.status === 'success') {
        setPriceRange(res1?.data?.result);
      } else {
        clearPriceRangeAndOpenPopup();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        setSendDataLoading(false);
      }, 500);
    }
  };
  

  return {
    formData,
    handleBrandChange,
    handleSerialNumberChange,
    handleConditionChange,
    handlePapersChange,
    handleBoxChange,
    handleKaratsChange,
    handleBandMaterialChange,
    handleCaseMaterialChange,
    handleYearChange,
    handleMonthChange,
    handleMarketplaceChange,
    handleSubmit,
    handleGoldTypeChange,
    isButtonDisabled,
    serialNumbers,
    setSerialNumbers,
    sendDataLoading,
    isPopupOpen,
    setPopupOpen,
    handleDialColorChange
  };
};
