import { Link } from 'react-router-dom';

import { Button, InputField } from '@components/Form';
import { Container } from '@components/Container';
import { useSignInLogic } from '@components/Auth/SignInForm/useSignInLogic';

export const SignInForm = () => {
  const { email, password, onEmailChange, onPasswordChange, onSubmit, error, isDisplayError, loading, isInvalid } =
    useSignInLogic();

  return (
    <Container>
      <h1 className="text-2xl font-bold mb-8 text-center">Sign In</h1>
      <form className="w-[185px] sm:w-[400px] mx-auto" onSubmit={onSubmit}>
        <InputField
          label="Email"
          type="email"
          error={error.email}
          value={email}
          onChange={onEmailChange}
          containerClassName="mb-8"
        />
        <InputField
          type="password"
          label="Password"
          error={error.password}
          value={password}
          onChange={onPasswordChange}
        />
        <p className="mt-4">
          <Link to="/forgot-password" className="text-sm text-blue-600 hover:text-blue-800 cursor-pointer">
            Forgot password?
          </Link>
        </p>
        <p className="mt-4">
          <Link to="/auth/sign-up" className="text-sm text-blue-600 hover:text-blue-800 cursor-pointer">
            Don't have an account yet?
          </Link>
        </p>
        {isDisplayError && <p className="mt-4 text-lg text-red-500">{isDisplayError}</p>}
        {loading ? (
          <Button className="mt-8">
            <div className="w-full h-full flex justify-center items-center">
              <div className="buttonSpinner" />
            </div>
          </Button>
        ) : (
          <Button className="mt-8" type="submit" disabled={isInvalid}>
            Sign In
          </Button>
        )}
      </form>
    </Container>
  );
};
