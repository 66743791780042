import React, { InputHTMLAttributes, useState } from 'react';

interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  checked: boolean,
  handleChange: () => void,
}

export const Checkbox: React.FC<ICheckboxProps> = ({ label, checked, handleChange,  ...props }) => {

  return (
    <label className="flex items-center space-x-2 mt-1">
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 flex-shrink-0"
        {...props}
        checked={checked}
        onChange={handleChange}
      />
      <span className="text-gray-text">{label}</span>
    </label>
  );
};
