import React from 'react';

type FieldWrapperProps = {
  label: string;
  error: string;
  children: React.ReactNode
  className?: string;
}

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;


export const FieldWrapper = ({label, error, children, className}: FieldWrapperProps) => {
  return (
    <div className={className ? className : ''}>
      <div className="text-gray-text whitespace-nowrap">
        <label className="block mb-2 capitalize">{label}</label>
        {children}
      </div>
      {error && <p className="text-xs text-red-600">{error}</p>}
    </div>
  )
}
