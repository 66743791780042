import React, { useEffect, useState } from 'react';
import { Button, InputField, Select, Checkbox } from '@components/Form';
import { useWatchFormLogic } from './useWatchFormLogic';
import { BRANDS, CASE_MATERIALS, BAND_MATERIALS, KARATS, MONTHS, YEARS, COLORS, GOLD_TYPES } from '../../constants';
import { getSerialNumberByBrand } from 'api/api';
import Popup from '@components/ErrorPopUp';

export const WatchForm = () => {
  const {
    formData,
    handleBrandChange,
    handleSerialNumberChange,
    handleConditionChange,
    handlePapersChange,
    handleBoxChange,
    handleKaratsChange,
    handleBandMaterialChange,
    handleCaseMaterialChange,
    // handleYearChange,
    // handleMonthChange,
    // handleMarketplaceChange,
    handleSubmit,
    isButtonDisabled,
    serialNumbers,
    setSerialNumbers,
    sendDataLoading,
    isPopupOpen,
    setPopupOpen,
    handleDialColorChange,
    handleGoldTypeChange
  } = useWatchFormLogic();

  const {
    selectedBrand,
    selectedSerialNumber,
    selectedCondition,
    selectedPapers,
    selectedBox,
    selectedKarats,
    selectedBandMaterial,
    selectedCaseMaterial,
    selectedDialColor,
    // selectedYear,
    // selectedMonth,
    // marketplace,
    selectedGoldType
  } = formData;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedBrand) {
      setLoading(true);
      getSerialNumberByBrand(selectedBrand as string)
        .then((response: any) => setSerialNumbers(response?.data?.refnos))
        .finally(() => serialNumbers && setTimeout(() => setLoading(false), 500));
    }
  }, [selectedBrand, setSerialNumbers]);

  useEffect(() => {
    if (selectedBandMaterial === 'Gold' || selectedCaseMaterial === 'Gold') {
      handleKaratsChange(18);
    } else {
      handleKaratsChange(0);
    }
  }, [selectedCaseMaterial, selectedBandMaterial]);

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-wrap mt-12 w-11/12 sm:flex mx-auto w-full md:mt-32">
      {isPopupOpen && <Popup isPopupOpen={isPopupOpen} onClose={handleClosePopup} />}
      <div className="w-full sm:w-1/2 px-4">
        <div className="mb-4 ">
          <Select
            label="Brand"
            error=""
            options={BRANDS.map((brand) => ({ label: brand, value: brand }))}
            value={selectedBrand}
            onChange={handleBrandChange}
            placeholder="Select Brand"
            readonly={false}
          />
        </div>
        <div className="mb-4 ">
          {loading ? (
            <div className="w-full flex justify-center items-center mt-12 sm:mt-14">
              <div className="spinner" />
            </div>
          ) : (
            <Select
              label="Ref. Number"
              error=""
              options={serialNumbers?.map((serialNumber) => ({ label: serialNumber, value: serialNumber }))}
              value={selectedSerialNumber}
              onChange={handleSerialNumberChange}
              placeholder="Ref. Number"
              readonly={false}
            />
          )}
        </div>
        <div className="mb-4">
          <Select
            label="Condition"
            error=""
            options={[
              { label: 'Unknown', value: 'no condition' },
              { label: 'Poor', value: 'poor' },
              { label: 'Very good', value: 'very good' },
              { label: 'Excellent', value: 'excellent' }
            ]}
            value={selectedCondition}
            onChange={handleConditionChange}
            placeholder="Condition"
          />
        </div>
        <div className="mb-4">
          <Select
            label="Papers"
            error=""
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ]}
            value={selectedPapers}
            onChange={handlePapersChange}
            placeholder="Papers"
          />
        </div>
        <div className="mb-4">
          <Select
            label="Gold Type"
            error=""
            options={GOLD_TYPES.map((material) => ({ label: material, value: material }))}
            value={selectedGoldType}
            onChange={handleGoldTypeChange}
            placeholder="Gold Type"
            readonly={false}
          />
        </div>
      </div>
      <div className="w-full sm:w-1/2 px-4">
        <div className="mb-4">
          <Select
            label="Box"
            error=""
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' }
            ]}
            value={selectedBox}
            onChange={handleBoxChange}
            placeholder="Box"
          />
        </div>
        <div className="mb-4 ">
          <Select
            label="Dial color"
            error=""
            options={COLORS.map((karat) => ({ label: karat, value: karat }))}
            value={selectedDialColor}
            onChange={handleDialColorChange}
            placeholder="Dial color"
          />
        </div>
        <div className="mb-4">
          <Select
            label="Band Material"
            error=""
            options={BAND_MATERIALS.map((material) => ({ label: material, value: material }))}
            value={selectedBandMaterial}
            onChange={handleBandMaterialChange}
            placeholder="Band Material"
            readonly={false}
          />
        </div>
        <div className="mb-4">
          <Select
            label="Case Material"
            error=""
            options={CASE_MATERIALS.map((material) => ({ label: material, value: material }))}
            value={selectedCaseMaterial}
            onChange={handleCaseMaterialChange}
            placeholder="Case Material"
            readonly={false}
          />
        </div>
      </div>
      {sendDataLoading ? (
        <Button className="mt-10 mx-2">
          <div className="w-full h-full flex justify-center items-center">
            <div className="buttonSpinner" />
          </div>
        </Button>
      ) : (
        <div className="w-full mt-10 mx-2 ">
          <Button type="submit" className="w-full" disabled={isButtonDisabled}>
            Submit
          </Button>
        </div>
      )}
    </form>
  );
};
