import React from "react";
import clsx from "clsx";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'default' | 'other'
}

export const Button = ({ children, className, onClick, type = 'button', disabled, ...props }: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={clsx(
        'w-full h-10 font-bold rounded transition-all',
        {
          'bg-blue-500 text-white hover:bg-blue-600': !disabled,
          'bg-gray-300 text-gray-500 cursor-not-allowed': disabled, 
        },
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};
