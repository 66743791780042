import { checkboxesApiCall, Register } from 'api/api';
import { useState, useCallback, ChangeEvent, FormEvent, useContext, useEffect } from 'react';

import { validateSignUpForm, SignUpFormErrors } from './validations';

import { useNavigate } from 'react-router-dom';
import AuthContext from 'context/AuthContext';

const INITIAL_FORM_ERRORS: SignUpFormErrors = {
  email: '',
  password: '',
  confirmPassword: ''
};

export const useSignupLogic = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [lending, setLending] = useState<boolean>(false);
  const [selling, setSelling] = useState<boolean>(false);
  const [insurance, setInsurance] = useState<boolean>(false);
  const [isDisplayError, setIsDisplayError] = useState<string>('');
  const [error, setError] = useState<SignUpFormErrors>(INITIAL_FORM_ERRORS);
  const [loading, setLoading] = useState<boolean>(false);
  const { setAuthenticated, setGlobalEmail } = useContext(AuthContext);
  const [isInvalid, setIsInvalid] = useState<boolean>(false)

  useEffect(() => {
    if(email.length < 1){
      setIsInvalid(true)
    }
    if(password.length < 7 || confirmPassword.length < 7){
      setIsInvalid(true)
    }
    if(password !== confirmPassword){
      setIsInvalid(true)
    }
    if(email.length > 0 && password.length > 7 && confirmPassword.length > 7 && confirmPassword === password){
      setIsInvalid(false)
    }
  }, [password, confirmPassword, email])

  const onPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, password: '' }));
  }, []);

  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, email: '' }));
  }, []);

  const onConfirmPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    setError((prevErrors) => ({ ...prevErrors, confirmPassword: '' }));
  }, []);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      const submitData = { email, password };
      const errors = validateSignUpForm({ ...submitData, confirmPassword });
      if (!errors.email && !errors.password) {
        setLoading(true)
        Register({ password: password, email: email, confirmPassword: confirmPassword })
          .then((res) => {
            if(res?.status === 201){
              checkboxesApiCall({email: email, lending: lending, selling: selling, insurance: insurance})
              setAuthenticated(true)
              setGlobalEmail(email)
              navigate('/home');
            }else{
              setIsDisplayError(res?.data?.non_field_errors[0]);
            }
          })
          .catch((e: any) => {
            console.error(e);
            setIsDisplayError(e.response?.data?.non_field_errors[0]);
          })
          .finally(() => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
          });;
      } else {
        setError(errors);
      }
    },
    [email, password, confirmPassword, navigate, setAuthenticated, setGlobalEmail]
  );

  return {
    email,
    password,
    confirmPassword,
    lending,
    selling,
    insurance,
    onEmailChange,
    onPasswordChange,
    onConfirmPasswordChange,
    onSubmit,
    error,
    isDisplayError,
    loading,
    setLending,
    setInsurance,
    setSelling,
    isInvalid
  };
};
