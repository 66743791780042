export type SignUpFormErrors = {
  email: string;
  password: string;
  confirmPassword: string;
};

export type RegisterFormData = {
  email: string;
  password: string;
  confirmPassword: string;
};

export type CheckboxesData = {
  email: string,
  lending: boolean,
  selling: boolean,
  insurance: boolean
}

// TODO: Extend the validations (potentially make them generic, so it also works for the sign in)
export const validateSignUpForm = (data: RegisterFormData, isSignUp?: boolean): SignUpFormErrors => {
  const errors: SignUpFormErrors = { email: '', password: '', confirmPassword: '' };
  const { email, password } = data;

  if (!email) {
    errors.email = 'This field is required';
  }

  if (!password) {
    errors.password = 'This field is required';
  } else if (password.length < 6) {
    errors.password = 'Password is too weak';
  }

  if (isSignUp && data.password !== data.confirmPassword) {
    errors.confirmPassword = 'Passwords should match';
  }

  return errors;
};
