export const BRANDS = [
  "A. Lange & Söhne", "Alpina", "Audemars Piguet", "Ball", "Baume & Mercier",
  "Bell & Ross", "Blancpain", "Breguet", "Breitling", "Bulova", "BVLGARI",
  "Cartier", "Casio", "Certina", "Chopard", "Citizen", "Damasko", "DOXA", "Eterna",
  "FORTIS", "Frederique Constant", "Girard-Perregaux", "Glashütte Original",
  "Glycine", "Graf Zeppelin", "Grand Seiko", "Hamilton", "Heuer", "H. Moser & Cie",
  "Hublot", "IWC", "Jaeger-LeCoultre", "Junghans", "Junkers", "Laco", "Longines",
  "Luminox", "Maratac", "Marathon", "MeisterSinger", "Mido", "Ming", "Mondaine",
  "Montblanc", "Nomos", "Omega", "Orient", "Oris", "Panerai", "Patek Philippe",
  "Piaget", "Rado", "Raymond Weil", "Rolex", "Seiko", "Sinn", "Squale",
  "Steinhart", "Swatch", "TAG Heuer", "Tissot", "Tudor", "Ulysse Nardin",
  "Vacheron Constantin", "Victorinox", "Zenith", "Zodiac"
]

export const CASE_MATERIALS = ["Bronze", "Ceramic", "Diamond", "Gold", "Platinum", "Resin", "Steel", "Silver", "Titanium", "Unknown"]
export const BAND_MATERIALS = ["Bronze", "Exotic Leather", "Diamond", "Gold", "Leather", "Platinum", "Resin", "Rubber", "Steel", "Silver", "Titanium", "Unknown"]

export const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December'
];

export const COLORS = ["Blue", "Brown", "Champagne", "Beige", "Black" , "Cream", "Gold", "Gray", "Green", "Grey", "Ivory", "Multicolor", "Red", "Silver", "Skeleton", "Unknown", "White", "Yellow"]

export const GOLD_TYPES = ["Bi-Color", "Gold Plated", "Gold Filled", "Rose Gold", "White Gold", "Yellow gold", "Not Present"]


export const getMonthNumericalRepresentation = (month: string): string | null => {
  const index = MONTHS.findIndex(m => m === month);
  if (index !== -1) {
    const monthNumber = index < 9 ? '0' + ( index + 1 ) : index + 1;
    return monthNumber.toString();
  }
  return null;
};

export const getBooleanValue = (value: string): boolean => {
  if(value === 'Yes'){
    return true
  } else {
    return false
  }
}

const currentYear = new Date().getFullYear();
const startYear = 2010;
export const YEARS = Array.from({ length: currentYear - startYear + 1 }, (_, index) => (currentYear - index).toString());

export const KARATS = ['0', '14', '16', '18', '24']