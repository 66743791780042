import { useState, ChangeEvent, useCallback } from 'react';

import clsx from 'clsx';

import { FieldWrapper, FieldWrapperPassThroughProps } from "./FieldWrapper";

import OpenEye from '../../assets/icons/eye-opened.svg';
import ClosedEye from '../../assets/icons/eye-closed.svg';

type InputFieldProps = FieldWrapperPassThroughProps & {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: 'text' | 'email' | 'password';
  className?: string;
  containerClassName?: string;
  placeholder?: string;
};

export const InputField = (props: InputFieldProps) => {
  const { value, type = 'text', label, placeholder, className, error, containerClassName, onChange } = props;
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);

  const toggleEye = useCallback(() => {
    setIsPasswordHidden( prevState => !prevState)
  }, []);

  const inputType = isPasswordHidden ? 'password' : 'text';

  return (
    <FieldWrapper label={label} error={error} className={containerClassName}>
      <div className='relative'>
        <input
          value={value}
          onChange={onChange}
          type={ type === 'password' ? inputType : type}
          className={clsx(
            `${type === 'password' ? 'pl-3 pr-8' : 'px-3'} appearance-none block w-full py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`,
            className
          )}
          placeholder={placeholder}
        />
        {type === 'password' ?
          <img
            className="h-6 absolute right-2 top-2 sm:top-1.5 cursor-pointer"
            onClick={toggleEye}
            src={isPasswordHidden ? ClosedEye : OpenEye}
            alt='password eye'
          />
          : null
        }
      </div>
    </FieldWrapper>
  );
};
