import React from 'react';
import { Header } from '@components/Header';
import { Outlet } from 'react-router-dom';

export const AuthLayout = () => {
  return (
    <div className="bg-gray-bg h-screen w-screen">
      <Header />
      <div className="flex items-center justify-center h-full py-16">
        <Outlet />
      </div>
    </div>
  );
};
