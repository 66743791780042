import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/icons/logo.svg';
import { useContext, useState } from 'react';
import { LogOut } from 'api/api';
import AuthContext from 'context/AuthContext';

export const Header = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const { setAuthenticated, authenticated, email, setGlobalEmail } = useContext(AuthContext);

  const handleLogout = () => {
    setLoading(true);
    LogOut()
      .then(() => {
        setAuthenticated(false);
        setGlobalEmail('');
        navigate('/auth/sign-in');
      })
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="w-full z-50 flex bg-white border-b-1 items-center justify-between border border-b-gray-border fixed top-0">
      <div className="flex justify-center sm:justify-start sm:mx-8 sm:px-1 sm:py-2 md:mx-20 flex-col">
        <div className='flex flex-row items-center'>   
        <Link to="/">
          <img className="h-16" src={Logo} alt="Logo" />
        </Link>
        <h2 className="font-extrabold text-2xl ml-2 hidden sm:block whitespace-nowrap">Pricing Portal</h2>
        </div>  
        <p className='hidden sm:block italic'>Barker in beta, not yet supporting pricing of all brands and Ref. numbers</p>
      </div>
      <div className="mr-4 sm:mr-24">
        <button
          className="flex items-center text-gray-800 focus:outline-none"
          onClick={authenticated ? handleToggleDropdown : () => {}}
        >
          {/*<img src="user-avatar.png" alt="User Avatar" className="w-8 h-8 rounded-full mr-2" />*/}
          <span className="ml-4 mr-2 sm:ml-2 mr-16 md:mr-0 md:ml-2 whitespace-nowrap md:block max-w-[150px] md:max-w-[none]">
            {email}
          </span>
        </button>
        {isDropdownOpen && (
          <ul className="absolute right-100 mt-2 py-2 w-48 bg-white shadow-md rounded-md">
            <li className="px-4 py-2 hover:bg-gray-100">
              {loading ? (
                <div className="w-full h-full flex justify-center items-center">
                  <div className="spinner" />
                </div>
              ) : (
                <button className="w-full text-left" onClick={handleLogout}>
                  Logout
                </button>
              )}
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};
