import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import { FieldWrapper, FieldWrapperPassThroughProps } from '@components/Form/FieldWrapper';

interface IOption {
  value: number | string;
  label: string;
}

type SelectProps = FieldWrapperPassThroughProps & {
  value: number | string;
  onChange: (value: IOption['value']) => void;
  options: IOption[];
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  readonly?: boolean;
};

export const Select = ({
  value,
  onChange,
  options,
  className,
  label,
  error,
  disabled,
  readonly = true,
  placeholder
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const filteredOptions = options ? options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase())) : [];

  const handleSelect = (selectedValue: IOption['value']) => {
    setInputValue('');
    onChange(selectedValue);
    setIsOpen(false);
  };

  const selectedOption = options ? options.find((option) => option.value === value) : '';

  const isValueInvalid = useMemo(()=> {
    if(inputValue){
      return !options.some((option) => option.value.toString().includes(inputValue));
    } 
  }, [inputValue])

  return (
    <FieldWrapper label={label} error={error}>
      <div className={clsx('relative', className)}>
        <input
          value={inputValue}
          onChange={(e) =>  setInputValue(e.target.value)}
          className={clsx(
            'appearance-none cursor-pointer block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm',
            { 'placeholder-gray-900': selectedOption, 'placeholder-gray-400': !selectedOption, 'focus:ring-blue-500 focus:border-blue-500': !isValueInvalid },
            isOpen && 'rounded-t-md',
            disabled && 'cursor-not-allowed opacity-50',
            isValueInvalid && 'border-red-500 text-red-500',
            className
          )}
          placeholder={selectedOption ? selectedOption.label : placeholder ? placeholder : ''}
          disabled={disabled}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
          readOnly={readonly || (readonly && !isOpen)}
        />
        {isOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md max-h-72 overflow-y-auto fadeIn">
            {readonly
              ? options.map((option) => (
                  <div
                    key={option.value}
                    className={clsx(
                      'py-2 px-4 cursor-pointer',
                      value === option.value && 'bg-blue-500 text-white',
                      value !== option.value && 'hover:bg-gray-100'
                    )}
                    onMouseDown={() => handleSelect(option.value)}
                  >
                    {option.label}
                  </div>
                ))
              : filteredOptions.map((option) => (
                  <div
                    key={option.value}
                    className={clsx(
                      'py-2 px-4 cursor-pointer',
                      value === option.value && 'bg-blue-500 text-white',
                      value !== option.value && 'hover:bg-gray-100'
                    )}
                    onMouseDown={() => handleSelect(option.value)}
                  >
                    {option.label}
                  </div>
                ))}
          </div>
        )}
      </div>
    </FieldWrapper>
  );
};
